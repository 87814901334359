<template>
  <div class="order-detail">
    <h2 class="sr-only">{{ $t('views.orders.detail.title') }}</h2>

    <loader v-if="is.loading"/>

    <template v-else>

      <div class="order-header">
        <dl>
          <div>
            <dt>{{ $t('components.order.header.date.label')  }}</dt>
            <dd>{{ moment(entity.created).format('DD.MM.YYYY HH:mm:ss') }}</dd>
          </div>
          <div>
            <dt>{{ definition.$properties.orderId.translations[$i18n.locale].name }}</dt>
            <dd>{{ entity.orderId }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.deliverytype.label') }}</dt>
            <dd v-if="entity.configuration.deliveryType">{{ $t(`components.order.configuration.delivery.type.${entity.configuration.deliveryType.toLowerCase()}`) }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.paymenttype.label') }}</dt>
            <dd>{{ entity.configuration.payrexxPaymentType }}</dd>
          </div>

          <div>
            <dt><span v-html="entity.executedBasket.priceDetail.totalPriceLabel[$i18n.locale]"></span></dt>
            <dd>{{ $n(entity.executedBasket.priceDetail.totalPrice, 'currency') }}</dd>
          </div>

          <div>
            <dt>{{ definition.$properties.orderState.translations[$i18n.locale].name }}</dt>
            <dd>
              <b-badge :variant="orderStateBadgeVariants[entity.orderState]">{{ valueFormatter(definition, 'orderState', entity.orderState).value }}</b-badge>
            </dd>
          </div>
        </dl>
      </div>
      <template v-if="entity.voigtOrderData">
        <div class=" mt-3">
          <h2>Voigt Bestellungs Informationen</h2>
          <p v-if="entity.voigtOrderData.orderDate">
            Bestelldatum: {{ moment(entity.voigtOrderData.orderDate).format('DD.MM.YYYY HH:mm:ss') }}
          </p>
        </div>

        <div class="accordion" id="headers">
          <div class="card" v-for="(header,index) in entity.voigtOrderData.headerLines" :key="index">
            <div class="card-header" :id="`heading-${index}`">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="`#collapse-${index}`" aria-expanded="true" :aria-controls="`collapse-${index}`">
                  Header Line #{{ index + 1 }}
                </button>
              </h2>
            </div>

            <div :id="`collapse-${index}`" class="collapse show" :aria-labelledby="`heading-${index}`" data-parent="#headers">
              <div class="card-body">
                <p>
                  Handling Unit Number: {{header.handlingUnitNumber }}
                </p>
                <p>
                  Tracking Number: <a :href="`https://www.post.ch/swisspost-tracking?formattedParcelCodes=${header.trackingNumber}`" target="_blank">{{ header.trackingNumber }}</a>
                </p>
                <div class="card mt-3" v-for="(item,i) in header.itemLines" :key="i">
                  <div class="card-body">
                    <p>
                      Pharma Code: {{item.pharmaCode }}
                    </p>
                    <p>
                      Order Quantity: {{item.orderQuantity }}
                    </p>
                    <p>
                      Delivery Quantity: {{item.deliveryQuantity }}
                    </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center p-4">
          <p>Keine Voigt Daten vorhanden</p>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { ORDER_STATES, ORDER_STATES_BADGES_VARIANTS, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import { valueFormatter } from '@/assets/js/helper/entity'

import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'
import moment from 'moment'

export default {
  name: 'Orders.Detail',
  mixins: [MixinEntityBase],
  components: {
    Loader
  },
  data () {
    return {
      orderStateBadgeVariants: Object.keys(ORDER_STATES)
        .reduce((variants, sKey) => Object.assign(variants, { [ORDER_STATES[sKey]]: ORDER_STATES_BADGES_VARIANTS[sKey] }), {}),
      moment
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eParent.key}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    },
    summary () {
      const priceDetails = this.entity.executedBasket.priceDetail || {}

      return []
        .concat(priceDetails.priceLines ?? [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel ?? {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$i18n.locale]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    }
  },
  methods: {
    valueFormatter
  }
}
</script>

<style lang="scss">
.order-detail {
  .order-header {
    padding: $spacer;
    background-color: $gray-200;

    dl {
      display: grid;
      grid-gap: $spacer * 0.75;
      grid-auto-flow: column;
      grid-template-columns: auto;
      margin: 0;

      dd {
        margin: 0;
      }
    }
  }

  .order-basket {
    margin-top: $spacer * 2;
  }

  .order-configuration {
    margin-top: $spacer * 2;
  }
}
</style>
